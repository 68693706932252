<template>
  <section>
    <title-section title="Dolar"> </title-section>
    <dollar-main />
  </section>
</template>

<script>
export default {
  name: 'Dolar',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    DollarMain: () => import('@/components/Dollar/DollarMain.vue')
  }
}
</script>

<style lang="scss" scoped>
</style>
